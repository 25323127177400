<!--eslint-disable-->
<template>
	<div
		id="mutualFundModal"
		aria-labelledby="contactModalTitle"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal__header">
					<h3 class="modal-title" id="userModalTitle">Add Mutual Fund</h3>
					<button
						@click.prevent="clearErrors()"
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body modal__body">
					<form class="modal__form" method="post" @submit.prevent="addMutualFund">
						<div class="form-row">
							<div class="form-group col-md-6">
								<label class="modal__label" for="name">Name</label>
								<input
									:class="[{ 'is-invalid': errors.title }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.title"
									name="title"
									value=""
									type="text"
									class="form-control"
									id="name"
									placeholder="e.g ABC Capital"
								/>
								<span class="modal__form__icon">
									<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<title>Person Icon</title>
										<path fill="none" d="M0 0h24v24H0z" />
										<path fill="none" d="M4 4h16v16H4z" />
										<path
											d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 
										2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
											fill="#828282"
										/>
									</svg>
								</span>
								<div class="invalid-feedback">
									{{ errors.title }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="minimum_investment_amount">
									Minimum Investment Amount
								</label>
								<input
									:class="[{ 'is-invalid': errors.minimum_investment_amount }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.minimum_investment_amount"
									name="minimum_investment_amount"
									value=""
									type="number"
									class="form-control"
									id="minimum_investment_amount"
									placeholder="e.g 500"
								/>
								<div class="invalid-feedback">
									{{ errors.minimum_investment_amount }}
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-md-6">
								<label class="modal__label" for="currency">Currency</label>
								<select
									v-model="itemData.currency"
									class="form-control"
									style="height: 3rem;"
									name="currency"
									id="currency"
									:class="[{ 'is-invalid': errors.currency }]"
									@change="clearErrors($event.target.name)"
								>
									<option value="NGN">NGN</option>
									<option value="USD">USD</option>
								</select>
								<div class="invalid-feedback">
									{{ errors.currency }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="returns">Returns</label>
								<input
									:class="[{ 'is-invalid': errors.returns }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.returns"
									name="returns"
									value=""
									type="number"
									class="form-control"
									id="returns"
									placeholder="e.g 10"
								/>
								<div class="invalid-feedback">
									{{ errors.returns }}
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="form-group col-md-6">
								<label class="modal__label" for="fund_custodian">Fund Custodian</label>
								<input
									:class="[{ 'is-invalid': errors.fund_custodian }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.fund_custodian"
									name="fund_custodian"
									value=""
									type="text"
									class="form-control"
									id="fund_custodian"
									placeholder="e.g Citibank Nigeria"
								/>
								<div class="invalid-feedback">
									{{ errors.fund_custodian }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="fund_trustees">Fund Trustees</label>
								<input
									:class="[{ 'is-invalid': errors.fund_trustees }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.fund_trustees"
									name="fund_trustees"
									value=""
									type="text"
									class="form-control"
									id="fund_trustees"
									placeholder="e.g First trustees"
								/>
								<div class="invalid-feedback">
									{{ errors.fund_trustees }}
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="form-group col-md-6">
								<label class="modal__label" for="fund_manager">Fund Manager</label>
								<input
									:class="[{ 'is-invalid': errors.fund_manager }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.fund_manager"
									name="fund_manager"
									value=""
									type="text"
									class="form-control"
									id="fund_manager"
									placeholder="e.g XYZ Enterprise"
								/>
								<div class="invalid-feedback">
									{{ errors.fund_manager }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="fund_risk_level">Fund Risk Level</label>
								<select
									v-model="itemData.fund_risk_level"
									class="form-control"
									style="height: 3rem;"
									name="fund_risk_level"
									id="fund_risk_level"
									:class="[{ 'is-invalid': errors.fund_risk_level }]"
									@change="clearErrors($event.target.name)"
								>
									<option value="Low">Low</option>
									<option value="Medium">Medium</option>
									<option value="High">High</option>
								</select>
								<div class="invalid-feedback">
									{{ errors.fund_risk_level }}
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="form-group col-md-6">
								<label class="modal__label" for="fund_size">Fund Size</label>
								<input
									:class="[{ 'is-invalid': errors.fund_size }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.fund_size"
									name="fund_size"
									value=""
									type="text"
									class="form-control"
									id="fund_size"
									placeholder="e.g 100, 000, 000"
								/>
								<div class="invalid-feedback">
									{{ errors.fund_size }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="fund_minimum_holding_period"
									>Fund Minimum Holding Period</label
								>
								<input
									:class="[{ 'is-invalid': errors.fund_minimum_holding_period }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.fund_minimum_holding_period"
									name="fund_minimum_holding_period"
									value=""
									type="number"
									class="form-control"
									id="fund_minimum_holding_period"
									placeholder="e.g 30"
								/>
								<div class="invalid-feedback">
									{{ errors.fund_minimum_holding_period }}
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="form-group col-md-6">
								<label class="modal__label" for="fund_income_distribution"
									>Fund Income Distribution</label
								>
								<select
									v-model="itemData.fund_income_distribution"
									class="form-control"
									style="height: 3rem;"
									name="fund_income_distribution"
									id="fund_income_distribution"
									:class="[{ 'is-invalid': errors.fund_income_distribution }]"
									@change="clearErrors($event.target.name)"
								>
									<option value="Daily">Daily</option>
									<option value="Monthly">Monthly</option>
									<option value="Quarterly">Quarterly</option>
									<option value="Annually">Annually</option>
									<option value="Periodically">Periodically</option>
								</select>
								<div class="invalid-feedback">
									{{ errors.fund_income_distribution }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="fund_portfolio">Fund Portfolio</label>
								<textarea
									:class="[{ 'is-invalid': errors.fund_portfolio }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.fund_portfolio"
									name="fund_portfolio"
									value=""
									rows="5"
									type="text"
									class="form-control"
									id="fund_portfolio"
									placeholder="e.g FGN Eurobonds 50.5%, Corporate 30%"
								/>
								<div class="invalid-feedback">
									{{ errors.fund_portfolio }}
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="form-group col-md-6">
								<label class="modal__label" for="fund_reason">Fund Reason</label>
								<input
									:class="[{ 'is-invalid': errors.fund_reason }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.fund_reason"
									name="fund_reason"
									value=""
									type="text"
									class="form-control"
									id="fund_reason"
									placeholder="Why choose this fund"
								/>
								<div class="invalid-feedback">
									{{ errors.fund_reason }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="past_performance">Past Performance</label>
								<textarea
									:class="[{ 'is-invalid': errors.past_performance }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.past_performance"
									name="past_performance"
									value=""
									type="text"
									class="form-control"
									id="past_performance"
									placeholder="eg 2018 10.5%, 2019 8.2%"
								/>
								<div class="invalid-feedback">
									{{ errors.past_performance }}
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="form-group col-md-6">
								<label class="modal__label" for="description">Description</label>
								<textarea
									rows="5"
									:class="[{ 'is-invalid': errors.description }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.description"
									name="description"
									value=""
									type="textarea"
									class="form-control"
									id="description"
									placeholder="Description of this mutual fund"
								/>
								<div class="invalid-feedback">
									{{ errors.description }}
								</div>
							</div>
							<div class="form-group col-md-6">
								<label class="modal__label" for="fund_management_fee">Fund Management Fee</label>
								<input
									:class="[{ 'is-invalid': errors.fund_management_fee }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.fund_management_fee"
									name="fund_management_fee"
									value=""
									type="number"
									class="form-control"
									id="fund_management_fee"
									placeholder="e.g 0.6"
								/>
								<div class="invalid-feedback">
									{{ errors.fund_management_fee }}
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer modal__footer">
					<button
						@click.prevent="clearErrors()"
						class="btn btn--md"
						type="button"
						data-dismiss="modal"
						aria-label="close"
						name="button"
					>
						Cancel
					</button>
					<button
						@click.prevent="addMutualFund"
						class="btn btn--md btn__primary"
						type="button"
						v-bind="{ disabled: loading }"
						name="button"
						id="addMutualFund"
					>
						Add Mutual Fund
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import mutualFunds from '../../utils/validations/mutualFunds';

export default {
	name: 'AddMutualFund',
	mixins: [form],
	data() {
		return {
			loading: false,
			itemData: {},
			errors: {}
		};
	},

	computed: {
		...mapGetters(['getStatus'])
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.clearFormField();
				this.clearErrors();
			}
		}
	},
	methods: {
		...mapActions(['createMutualFund']),
		addMutualFund() {
			this.validate(this.itemData, mutualFunds.create);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}

			/* eslint-disable-next-line */
			const { returns, fund_size, fund_minimum_holding_period, fund_management_fee, ...rest } = this.itemData;

			const payload = {
				...rest,
				returns: parseFloat(returns),
				fund_size: parseFloat(fund_size),
				fund_minimum_holding_period: parseFloat(fund_minimum_holding_period),
				fund_management_fee: parseFloat(fund_management_fee)
			};

			this.createMutualFund(payload);
			this.deleteEventFunc();
			return true;
		},
		deleteEventFunc() {
			this.$emit('addButton', 'addMutualFundClicked');
		}
	}
};
</script>

<style lang="scss" scoped>
select {
	height: 50px;
}
</style>

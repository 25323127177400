<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<p>{{ mutualFund.title }}</p>
			<p class="info__address">{{ mutualFund.currency }}</p>

			<p>
				{{ mutualFund.returns }}
			</p>
			<p>
				<span :class="isActive">
					{{ mutualFund.active === 1 ? 'Active' : 'Inactive' }}
				</span>
			</p>
			<p>
				<button class="btn-danger btn-sm" type="button" data-toggle="modal" data-target="#confirmDeleteModal">
					Delete
				</button>
			</p>
			<p>
				<router-link
					:to="{
						name: 'mutualFund-details',
						params: {
							id: mutualFund.id
						},
						query: {
							status: mutualFund.active
						}
					}"
					id="mutualFundDetail"
					class="btn btn__primary"
				>
					View Detail
				</router-link>
			</p>
		</div>
		<ConfirmDeleteModal :mutualFundId="mutualFund.id" />
	</div>
</template>

<script>
import ConfirmDeleteModal from './ConfirmDeleteModal';

export default {
	name: 'mutualFundItem',
	props: {
		mutualFundData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			mutualFund: this.mutualFundData,
			itemData: '',
			mapStatus: ''
		};
	},
	computed: {
		isActive() {
			return this.mutualFund.active === 1 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger';
		}
	},
	components: {
		ConfirmDeleteModal
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 1fr 1fr 1fr 1fr 1fr 0.4fr;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>

import { render, staticRenderFns } from "./ConfirmDeleteModal.vue?vue&type=template&id=61004e7d&scoped=true&"
import script from "./ConfirmDeleteModal.vue?vue&type=script&lang=js&"
export * from "./ConfirmDeleteModal.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmDeleteModal.vue?vue&type=style&index=0&id=61004e7d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61004e7d",
  null
  
)

export default component.exports
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"contacts--list__item"},[_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.mutualFund.title))]),_c('p',{staticClass:"info__address"},[_vm._v(_vm._s(_vm.mutualFund.currency))]),_c('p',[_vm._v("\n\t\t\t"+_vm._s(_vm.mutualFund.returns)+"\n\t\t")]),_c('p',[_c('span',{class:_vm.isActive},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.mutualFund.active === 1 ? 'Active' : 'Inactive')+"\n\t\t\t")])]),_vm._m(1),_c('p',[_c('router-link',{staticClass:"btn btn__primary",attrs:{"to":{
					name: 'mutualFund-details',
					params: {
						id: _vm.mutualFund.id
					},
					query: {
						status: _vm.mutualFund.active
					}
				},"id":"mutualFundDetail"}},[_vm._v("\n\t\t\t\tView Detail\n\t\t\t")])],1)]),_c('ConfirmDeleteModal',{attrs:{"mutualFundId":_vm.mutualFund.id}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"check"},[_c('input',{attrs:{"type":"checkbox"}}),_c('div',{staticClass:"check-mark"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('button',{staticClass:"btn-danger btn-sm",attrs:{"type":"button","data-toggle":"modal","data-target":"#confirmDeleteModal"}},[_vm._v("\n\t\t\t\tDelete\n\t\t\t")])])}]

export { render, staticRenderFns }